import '../scss/app.scss';
// start the Stimulus application
import '../bootstrap';

import "bootstrap"
import "magnific-popup"
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import mapboxgl from 'mapbox-gl';
import { WOW } from 'wowjs';
import './jquery.youtubebackground';

$(document).ready(function() {

    let NewsFormFilter = $('form[name="news_category_filter"]');
    let containerVideoYoutubeHeader = $('.container-header-video-youtube');
    let animationLottie = $('.animation-lottie');

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        containerVideoYoutubeHeader.css('pointer-event', 'inherit');
    }

    containerVideoYoutubeHeader.YTPlayer({
        fitToBackground: true,
        videoId: containerVideoYoutubeHeader.data('videocode'),
        playerVars: {
            modestbranding: 0,
            autoplay: 1,
            mute: 1,
            loop: 1,
            showinfo: 0,
            branding: 0,
            rel: 0,
            autohide: 0,
            playsinline: 1,
        }
    });

    //Declare animate Wow integral site
    new WOW({
        offset: 300,
    }).init();

    animationLottie.each(function() {
        console.log('ready');
        let anim = $(this).data('json-animation');
        bodymovin.loadAnimation({
            container: $(this)[0],// the dom element that will contain the animation
            path: anim,
            renderer: 'svg',
            loop: true,
            autoplay: true
        });
    });

    /**
     * Init slider Homepage
     * @type {Swiper}
     */
    const swiperHomepage = new Swiper('.swiper-container-homepage', {
        // Optional parameters
        loop: true,
        centeredSlides: true,
        roundLengths: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 3000,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        renderFraction: function (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' +
                ' of ' +
                '<span class="' + totalClass + '"></span>';
        },
        breakpoints: {
            768: {
                spaceBetween: 40,
                slidesPerView: 1.6,
            },
        },
        on: {
            transitionStart: function () {
                let heightContainer = $('.swiper-container-homepage').height();
                $('.swiper-container-homepage').css('min-height', heightContainer);
            },
            transitionEnd: function () {
                setTimeout(function () {
                    $('.swiper-container-homepage').css('min-height', 'auto');
                }, 1500);
            },
        }
    })

    /**
     * Init slider Value Elephant
     * @type {Swiper}
     */
    const swiperValueElephant = new Swiper('.swiper-container-value-elephant', {
        // Optional parameters
        loop: true,
        centeredSlides: true,
        roundLengths: true,
        spaceBetween: 20,
        slidesPerView: 1.2,
        autoplay: {
            delay: 3000,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        breakpoints: {
            768: {
                spaceBetween: 40,
                slidesPerView: 3,
            },
            480: {
                spaceBetween: 40,
                slidesPerView: 2,
            },
        },
        on: {
            transitionStart: function () {
                let heightContainer = $('.swiper-container-value-elephant').height();
                $('.swiper-container-value-elephant').css('min-height', heightContainer);
            },
            transitionEnd: function () {
                setTimeout(function () {
                    $('.swiper-container-value-elephant').css('min-height', 'auto');
                }, 1500);

            },
        }
    })

    /**
     * Collection Form
     * @type {jQuery|HTMLElement}
     */
    let $wrapper = $('.js-collection-medias-wrapper');
    $wrapper.on('click', '.js-remove-collection', function(e) {
        e.preventDefault();
        $(this).closest('.js-collection-item')
            .fadeOut()
            .remove();
    });
    $wrapper.on('click', '.js-collection-add', function(e) {
        e.preventDefault();
        let prototype = $wrapper.data('prototype');
        let index = $wrapper.data('index');
        let newForm = prototype.replace(/__name__/g, index);
        $wrapper.data('index', index + 1);
        $(this).before(newForm);
    });

    /**
     * Form filter news
     */
    NewsFormFilter.find('.js-submit-form input.form-check-input').change(function() {
        NewsFormFilter.submit();
    });

    /**
     * Init Page
     */
    stickyMenu();

    // Mapbox : Page contact
    let $map = jQuery('#elephant-map');
    if($map.length !== 1){
        return;
    }
    let zoom = 14;

    mapboxgl.accessToken = 'pk.eyJ1Ijoia2V2aW5naSIsImEiOiJjanJ0YnlwNmQyYzM2NDRudDE4M2d6MmZsIn0.5lA0WtmK7FANmqcuXonYkg';
    let map = new mapboxgl.Map({
        container: $map.attr('id'),
        style: 'mapbox://styles/kevingi/ckfzjigep0aml19s0g2xbbzjn',
        center: [-1.5751135,47.2062872],
        zoom: zoom,
    });
    let mapdataselector = jQuery('#data-map');
    let mapdata = mapdataselector.data('datamap-encode');

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.scrollZoom.disable();
    map.on("load", function () {
        mapdata.forEach(function (marker) {

            // create a HTML element for each feature
            let el = document.createElement('div');
            el.className = 'marker';

            new mapboxgl.Marker(el)
                .setLngLat(marker.coord)
                .addTo(map);
        });
    });
});

$(window).scroll(function () {
    stickyMenu();
});

function stickyMenu() {
    let HeaderBalise = $('header .main-menu .main-nav-block');
    let LogoHeader = $('header .logo-full-no-sticky');
    let LogoBalise = $('.main-logo-right-container-desktop .main-logo-right');
    let LogoImgBalise = $('.main-logo-right-container-desktop .main-logo-right img');
    if (window.innerWidth > 992) {
        if (window.pageYOffset <= 0) {
            $('.main-menu').removeClass('sticky');
            let Logo = LogoImgBalise.data('img');
            LogoImgBalise.attr('src', Logo);
            HeaderBalise.css('margin', '2rem 0 0 0');
            LogoBalise.css('top', '3rem');
            LogoHeader.removeClass('sticky-mode');
        } else if (window.pageYOffset > 0) {
            $('.main-menu').addClass('sticky');
            let LogoHover = LogoImgBalise.data('imghover');
            LogoImgBalise.attr('src', LogoHover);
            HeaderBalise.css('margin', '1.8rem 0 0 0');
            LogoBalise.css('top', '1rem');
            LogoHeader.addClass('sticky-mode');
        }
    } else {
        $('.main-menu').addClass('sticky');
        HeaderBalise.css('margin', '0 0 0 0');
        $('header .header-container').css('margin-top', '8rem');
    }
}